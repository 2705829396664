<template>
  <!-- 首页 -->
  <div class="app-content">
    <div class="navigatMenu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        text-color="#fff"
        active-text-color="#0AA4EE"
      >
        <img
          src="../assets/allImage/homePage/logo@2x.png"
          style="width: 21.5rem; cursor: pointer"
          @click="backIndex()"
        />
        <div class="navigatMenu-content">
          <div class="navigatMenu_title">
            <!-- 一级导航 -->
            <div
              @mouseleave="handleRemoveEvent($event)"
              class="routerLint"
              v-for="(item, indexs) in navigatList"
              :key="item.id"
              :class="{ primaryNavigation: indexs === couns }"
              @click="oneNavigationfN(indexs, item)"
              @mouseover="handleMouseover(indexs, item.id, item)"
            >
              {{ item.columnsName }}
            </div>
          </div>
          <el-input
            v-model="topInput"
            placeholder="关键词搜索"
            @keydown.enter.native="handleEnter"
          ></el-input>
        </div>
      </el-menu>
      <!-- 二级路由 -->
      <div
        class="twoLavelRouter"
        @mouseleave="handleMouseout($event)"
        @mouseover="twoMouseover()"
      >
        <router-link
          @click.native="towRouterLavelFn(index, SecondLevelRouting, obj)"
          class="navStyle"
          :class="{
            navDynamicStyle:
              $store.state.statusTypes == 0
                ? $store.state.secondLevel.id === obj.id
                : index === countent,
          }"
          v-for="(obj, index) in SecondLevelRouting"
          :key="obj.id"
          :to="{
            path: '/layout/companyProfile',
            query: {
              id: obj.id,
              columnPageType: obj.columnPageType,
              columnPageStyle: obj.columnPageStyle,
              isRegular: obj.isRegular,
              isSubStyle: obj.isSubStyle,
              paramID: obj.parentId,
              columnTheme: obj.columnTheme,
              isDatePageviews: obj.isDatePageviews,
            },
          }"
        >
          {{ obj.columnsName }}
        </router-link>
      </div>
    </div>

    <keep-alive>
      <router-view v-if="isActive"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import {
  headNavigationList,
  headNavigationSubcolumn,
  newsListpAGE,
} from '../api/index';
export default {
  data() {
    return {
      searchcontent: {
        pageNum: 1,
        pageSize: 10,
        title: '',
        columnId: '',
      },
      isActive: true, // 控制组件的激活状态
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      parentList: [],
      twoDis: 0,
      couns: JSON.parse(localStorage.getItem('INDEX_KEY')) || 0,
      countent: 0,
      screenWidth: 0,
      screenHeight: 0,
      activeIndex: '1',
      activeIndex2: '1',
      navigatList: [],
      // 二级路由
      SecondLevelRouting: [],
      // 搜索
      topInput: '',
      // 一级导航名字
      firstTitle: '',
      // 是否移入二级导航
      mouseState: 0,
      clickState: 0,
      turnList: [],
    };
  },
  activated() {
    // 组件被激活时触发，可以在此处重新创建组件或执行初始化逻辑
    // 例如，重新获取数据、重置状态等
    this.isActive = true;
  },
  deactivated() {
    // 组件被停用时触发，可以在此处进行组件的销毁或清理逻辑
    // 例如，取消订阅、清空数据等
    this.isActive = false;
  },
  methods: {
    // 返回首页
    backIndex() {
      this.$router.push('/');
      this.couns = 0;
    },
    // 输入框回车事件
    async handleEnter() {
      this.couns = 0;
      if (!this.topInput) {
        this.$message.error('请输入关键词搜索！');
        return;
      }
      this.$router.push({
        path: '/layout/companyProfile/public',
        query: { title: this.topInput },
      });
      // 一级导航请求
      // headNavigationList().then((request) => {
      //   request.data.forEach((element) => {
      //     if (element.id == 4) {
      //       // 把新闻中心筛选出来，搜索跳转到新闻中心
      //       this.turnList.push(element);
      //     }
      //   });
      //   // 子二级导航名字
      //   this.$store.state.secondLevel.columnsName =
      //     this.turnList[0].columnsName;
      //   this.$router.push({
      //     path: '/layout/companyProfile',
      //     query: {
      //       id: this.turnList[0].id,
      //       columnPageType: this.turnList[0].columnPageType,
      //       isRegular: this.turnList[0].isRegular,
      //       paramID: this.turnList[0].id,
      //       searchName: this.topInput,
      //     },
      //   });
      //   this.$store.dispatch('newsListFn', {
      //     columnId: this.turnList[0].id,
      //     title: this.topInput,
      //   });
      //   // 获取轮播图
      //   this.$store.dispatch('publicRotationChart', this.turnList[0].id);
      // });
      // 刷新新闻中心页面数据
    },
    // 一级鼠标移动事件
    async handleMouseover(indexs, id, item) {
      if (id !== 781127) {
        this.couns = indexs;
        this.firstTitle = item;
        // 鼠标放上去触发的操作
        const res = await headNavigationSubcolumn({ id: id });
        if (res.data === undefined) {
          let list = [];
          this.SecondLevelRouting = list;
        } else {
          this.SecondLevelRouting = res.data;
        }
      } else {
        this.couns = 0;
      }
      localStorage.setItem('INDEX_KEY_TEST', JSON.stringify(this.couns));
      // this.parentList = res.data;
    },
    // 二级移入
    twoMouseover() {},
    // 一级点击事件
    async oneNavigationfN(index, item) {
      if (item.id !== 781127) {
        this.$store.commit('commitHttps', item.id);
        this.queryParams.columnId = item.id;
        // 当一级导航只有单体页时
        if (item.columnPageType == 'MONOMER' && item.columnPageStyle == '') {
          this.$store.state.dynamicParentName = {};
          this.$store.state.dynamicParentName = item;
          this.$store.state.commonNavigationList = [];
          this.$store.state.secondLevel.columnsName = '';
          this.$router.push({
            path: '/layout/companyProfile/monomer',
            query: {
              id: item.id,
              paramID: item.parentId,
              isSubStyle: item.isSubStyle,
              isDatePageviews: item.isDatePageviews,
            },
          });
          localStorage.setItem('INDEX_KEY', JSON.stringify(this.couns));
          return;
        }
        // 保存页面状态
        this.$store.state.pageIdentity = item.columnPageType;
        // 外连接
        if (item.isRegular == 4) {
          window.open(
            'https://etc-website.gxetc.com.cn/#/login?redirect=%2FetcBusiness',
            '_blank'
          );
        }
        if (item.path == '/layout/index') {
          this.$router.push('/layout/index');
        }
        if (item.path == '/layout/serviceNetworkStation') {
          // 调用vuex的方法（ETC服务网点）
          this.$store.dispatch('searchNetwork');
          this.$router.push({
            path: '/layout/serviceNetworkStation',
            query: { id: item.id },
          });
        }
        // 切换二级导航昵称数据
        this.$store.state.dynamicParentName = item;
        //  公司概况
        if (item.isRegular == 1) {
          const res = await headNavigationSubcolumn({ id: item.id });
          // 改变公共二级导航的昵称
          if (res.status == 200) {
            if (res.data.length != 0) {
              this.$store.commit('commNavName', res.data[0]);
              this.clickState = res.data[0].id;
              this.$store.state.secondLevel.id = res.data[0].id;
              this.$router.push({
                path: item.path,
                query: {
                  id: res.data[0].id,
                  columnPageType: res.data[0].columnPageType,
                  isRegular: res.data[0].isRegular,
                  paramID: res.data[0].parentId,
                  // id: res.data[0].id,
                  // columnPageType: res.data[0].columnPageType,
                  // isRegular: res.data[0].isRegular,
                  columnPageStyle: res.data[0].columnPageStyle,
                  // paramID: res.data[0].parentId,
                  isSubStyle: res.data[0].isSubStyle,
                  columnTheme: res.data[0].columnTheme,
                },
              });
              this.$store.state.commonNavigationList = res.data;
              // 刷新公司概况页面数据
              this.$store.dispatch('companyProfileList', res.data[0].id);
              this.$store.state.newList = [];
            }
          }
        } else if (item.isRegular == 0) {
          const res = await headNavigationSubcolumn({ id: item.id });
          // 改变公共二级导航的昵称
          if (res.data.length !== 0) {
            this.$store.commit('commNavName', res.data);
            // 公共二级导航的id和名字
            this.$store.state.secondLevel.id = res.data[0].id;
            this.$store.state.secondLevel.columnsName = res.data[0].columnsName;
            this.$router.push({
              path: item.path,
              query: {
                id: res.data[0].id,
                isDatePageviews: res.data[0].isDatePageviews,
                columnPageType: res.data[0].columnPageType,
                columnPageStyle: res.data[0].columnPageStyle,
                isRegular: res.data[0].isRegular,
                isSubStyle: res.data[0].isSubStyle,
                paramID: res.data[0].parentId,
                columnTheme: res.data[0].columnTheme,
              },
            });
            // 判断块状还是列表，左侧还是顶部
            this.$store.state.columnPageType = res.data[0].columnPageType;
            this.$store.state.columnPageStyle = res.data[0].columnPageStyle;
            this.$store.state.isSubStyle = res.data[0].isSubStyle;
            this.$store.state.commonNavigationList = res.data;
            this.queryParams = {
              pageNum: 1, //当前页数
              pageSize: 10, //每页10条
              columnId: res.data[0].id,
            };
          } else {
            console.log('触发2');
            this.$store.commit('commNavName', res.data);
            this.$router.push({
              path: item.path,
              query: {
                id: item.id,
                columnPageType: item.columnPageType,
                columnPageStyle: item.columnPageStyle,
                isRegular: item.isRegular,
                isSubStyle: item.isSubStyle,
                paramID: item.parentId,
                columnTheme: item.columnTheme,
              },
            });
            // 判断块状还是列表，左侧还是顶部
            this.$store.state.columnPageType = item.columnPageType;
            this.$store.state.columnPageStyle = item.columnPageStyle;
            this.$store.state.isSubStyle = item.isSubStyle;
            this.$store.state.commonNavigationList = res.data;
          }
          this.$store.commit('globalpagingPageNum', this.queryParams);
          // 刷新新闻中心页面数据
          this.$store.dispatch('newsListFn', this.queryParams);
          this.$store.state.companyList = [];
        }
        // 获取轮播图
        this.$store.dispatch('publicRotationChart', item.id);
        this.couns = index;
      } else {
        this.$router.push('/layout/index');
        this.couns = 0;
      }
      localStorage.setItem('INDEX_KEY', JSON.stringify(this.couns));
    },
    // 一级移开事件
    handleRemoveEvent() {
      this.couns = JSON.parse(localStorage.getItem('INDEX_KEY'));
    },
    handleMouseout(event) {
      this.SecondLevelRouting = [];
      event.preventDefault(false);
    },
    // 二级点击事件
    towRouterLavelFn(index, SecondLevelRouting, obj) {
      this.queryParams.columnId = obj.id;
      this.$store.commit('commitHttps', obj.id);
      this.clickState = obj.id;
      // 获取轮播图
      this.$store.dispatch('publicRotationChart', obj.parentId);
      this.$store.state.dynamicParentName = this.firstTitle;
      this.$store.commit('commNavName', obj);
      this.$store.state.commonNavigationList = SecondLevelRouting;
      this.countent = index;
      // 修改状态
      this.$store.commit('changeState', 0);
      this.$store.dispatch('companyProfileList', obj.id);
      // 公司概况
      if (obj.isRegular == 2) {
        this.$store.dispatch('companyDepartmentSetting');
      } else {
        this.$store.commit('globalpagingPageNum', this.queryParams);
        this.$store.dispatch('newsListFn', this.queryParams);
      }
      localStorage.setItem(
        'INDEX_KEY',
        JSON.stringify(JSON.parse(localStorage.getItem('INDEX_KEY_TEST')))
      );
      this.couns = JSON.parse(localStorage.getItem('INDEX_KEY'));
    },
  },
  async created() {
    var list = [];
    var optios = {};
    optios.columnsName = '首页';
    optios.id = 781127;
    const res = await headNavigationList();

    res.data.unshift(optios);
    if (res.data != []) {
      res.data.map((item) => {
        var obj = {};
        if (item.columnsName === '首页') {
          obj.path = '/layout/index';
        } else if (item.isRegular === 0) {
          obj.path = '/layout/companyProfile';
        } else if (item.isRegular === 3) {
          obj.path = '/layout/serviceNetworkStation';
        } else if (item.isRegular === 4) {
          obj.path =
            'https://etc-website.gxetc.com.cn/#/login?redirect=%2FetcBusiness';
        } else {
          obj.path = '/layout/companyProfile';
        }
        obj.columnsName = item.columnsName;
        obj.id = item.id;
        obj.childrenNode = item.childrenNode;
        obj.isRegular = item.isRegular;
        obj.uuId = item.uuId;
        obj.parentId = item.parentId;
        obj.briefIntroduction = item.briefIntroduction;
        obj.columnTheme = item.columnTheme;
        obj.isSubColumns = item.isSubColumns;
        obj.isSubStyle = item.isSubStyle;
        obj.columnPageType = item.columnPageType;
        obj.columnPageStyle = item.columnPageStyle;
        obj.isDatePageviews = item.isDatePageviews;
        obj.createTime = item.createTime;
        obj.createBy = item.createBy;
        obj.updateTime = item.updateTime;
        list.push(obj);
      });
    }
    this.navigatList = list;
  },
  async mounted() {
    // console.log(789, res);
    // this.updateScreenSize();
    // window.addEventListener('resize', this.updateScreenSize);
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #fff;
  // margin-left: 1.25rem;
}
.app-content {
  position: relative;
  width: 100%;
  // 导航头
  .navigatMenu {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    .navigatMenu-content {
      display: flex;
      align-items: center;
      // background-color: red;
      .navigatMenu_title {
        overflow: hidden;
        width: 68.75rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        cursor: pointer;
        .routerLint {
          display: inline-block;
          height: 3.4375rem;
          line-height: 3.4375rem;
          padding: 0 0.9375rem;
          color: #fff;
          cursor: pointer;
        }
        .primaryNavigation {
          /* // height: 4.6875rem; */
          display: inline-block;
          background-color: rgba(51, 51, 51, 0.3);
          padding: 0 0.9375rem;
          color: #0aa4ee;
          border-bottom: 0.1875rem solid #0aa4ee;
          z-index: 999999999;
        }
        // 点击效果
        .el-menu-item.is-active {
          background-color: rgba(23, 23, 23, 0.2);
          border-bottom: 0.125rem solid #0aa4ee;
        }
        // 悬停时背景色
        .el-menu-item:hover {
          background-color: rgba(23, 23, 23, 0.2);
          color: #0aa4ee !important;
        }
        .el-menu-item {
          height: 5rem;
          display: flex;
          align-items: center;
        }
        // padding: 0.625rem 0;
        ::v-deep .el-submenu__title {
          background-color: rgba(23, 23, 23, 0.2);
        }
      }
    }
    .el-menu-demo {
      height: 5rem;
      display: flex;
      background-color: rgba(23, 23, 23, 0.5);
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 0rem;
      z-index: 999999999 !important;
    }
    .el-input {
      width: 12.5rem;
      // margin-left: 2.5rem;
      ::v-deep .el-input__inner {
        background-color: transparent;
        background-image: none;
        border-radius: 1.5625rem;
        border: 0.0625rem solid #dcdfe6;
        color: white;
      }
    }
  }
  // 二级路由
  .twoLavelRouter {
    width: 100%;
    // height: 2.5rem;
    background-color: rgba(25, 25, 25, 0.594);
    text-align: left;
    font-size: 1rem;
    color: #fff;
    line-height: 2.5rem;
    display: flex;
    justify-content: center;
    .navStyle {
      color: #fff;
      margin: 0.625rem 1.875rem;
    }
    .navDynamicStyle {
      color: #0aa4ee;
    }
  }
}
</style>
